import { ComputedRef } from 'vue'
import { ChatCarrier } from './chat-ad'

export const getTransferPhoneNumbers = async (
  afid: ComputedRef<string | undefined>,
  zipCode: string | null
): Promise<ChatCarrier[]> => {
  let carriers: ChatCarrier[] = []
  if (placementId.value) {
    try {
      const res = await axios.post(
        import.meta.env.VITE_WEBSITE_API_URL +
          '/mediaalpha/transfer-phone-numbers',
        {
          data: {
            zip: zipCode || ip.lookup?.postalCode,
            state: ip.lookup?.stateCode,
          },
          ip: ip.lookup?.value,
          local_hour: new Date().getHours(),
          max: 1,
          call_type: 'Inbound',
          transfer_type: 'cold',
          placement_id: placementId.value,
          ua: navigator.userAgent,
          url: location.href,
          version: 17,
          sub_1: getSessionEnhanced().query.afid || afid.value,
          sub_2: segment.anonymousId,
          sub_3: getSession().id,
          source: [
            getSessionEnhanced().query.afid || afid.value || 'null',
            query.utm_campaign || 'null',
            query.adgroupid || 'null',
            query.publisher_id || 'null',
            query.publisher || 'null',
            query.channel || 'null',
            xsmid.value || 'null',
          ],
        }
      )

      const data = res.data
      carriers = data.call_transfers

      analytics.track('transferNumbersResult', {
        maResponse: data,
      })
    } catch (e) {
      console.error(`There's some error loading transfer checks: `, { e })
    }
  }

  return carriers
}
