<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const notProd = import.meta.env.MODE !== 'production'

const source = ref(0)

const isPaidUser = ref(true)
const router = useRouter()

const afidsRedirectToClicks = [
  '811301',
  '811326',
  '811310',
  '811325',
  '811329',
  '811171',
  '811276',
  '811322',
  '811284',
  '811328',
  '811293',
  '811311',
  '811342',
  '811348',
]

const afid = computed(() => {
  return typeof phoneConfig.value === 'object' && 'afid' in phoneConfig.value
    ? phoneConfig.value.afid
    : undefined
})

const queryAfid = ref<string | null>()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  queryAfid.value = getSessionEnhanced().query.afid

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }
  enterExperiment('myOtcBenefitsImage')
})

const destination = computed(() => {
  const afidValue = queryAfid.value || afid.value
  if (
    import.meta.env.MODE === 'production' &&
    (!isMobile ||
      isOutsideWorkingHours.value ||
      afidsRedirectToClicks.some((a) => a === afidValue))
  ) {
    return '/ads'
  }

  let value =
    import.meta.env.MODE === 'production'
      ? '/additional-benefits-grocery-2024/call-now'
      : '/additional-benefits-grocery/call-now'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const state = states.find((state) => state.code === ip.lookup?.stateCode)

  const minOtc = state?.minOtc ?? 0
  const minOtcLtd = state?.minOtcLtd ?? 500

  source.value = notProd
    ? minOtc
    : isPaidUser.value
    ? minOtcLtd
    : Math.min(minOtcLtd, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

analytics.page({
  name: 'OTC Bridge 4',
  category: 'LP',
  product: 'medicare',
})

const card = ref(null)

const { height } = useElementSize(card)

const name = computed(() => {
  const firstname = query.firstname?.trim() || ''
  const lastname = query.lastname?.trim() || ''
  const fullName = `${firstname} ${lastname}`.trim()
  return fullName.length > 15 && firstname && lastname
    ? `${firstname.charAt(0)} ${lastname}`
    : fullName
})

const showBackButtonModal = ref(false)

onMounted(() => {
  const forward = router.options.history.state.forward
  if (typeof forward === 'string' && forward.includes('grocery')) {
    showBackButtonModal.value = true
  }
})
</script>

<template>
  <Layout
    hide-header
    footer="2025"
    class="bg-[#f7f7f7] min-h-screen p-6 relative"
  >
    <template #banner>
      <Banner> New to Medicare? Turning 65? </Banner>
    </template>
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded"
    >
      Ad
    </span>
    <div class="container !max-w-screen-sm py-6 space-y-6">
      <div class="text-4xl font-bold font-dm">
        You may get
        <span class="text-red text-5xl"> ${{ output.toFixed(0) }} </span>
        or more annual OTC allowance
      </div>

      <div>
        <img
          v-if="exp?.myOtcBenefitsImage?.newVersion"
          src="../../assets/my-otc-benefit-grocery-card.png"
          width="1080"
          height="1080"
          class="w-48 mx-auto"
        />
        <div v-else ref="card" class="aspect-[380/253] max-w-[380px] relative">
          <img
            src="../../assets/allowance-card-blank.png"
            class="w-full h-full"
          />
          <div
            class="absolute left-[8%] bottom-[11%] w-[62%] text-white uppercase font-sans font-normal"
            :style="{
              fontSize: `${height * 0.1}px`,
              lineHeight: `${height * 0.15}px`,
            }"
          >
            <template v-if="name">
              <Typewriter
                v-if="output === source"
                :text="name"
                class="truncate"
                cursor=""
                :speed="75"
              />
            </template>
            <div v-else class="truncate"> Emily Taylor </div>
          </div>
        </div>
      </div>

      <div class="text-gray-600 text-center font-bold">
        With some Medicare Advantage Plans from carriers like Humana and
        UnitedHealthcare®
      </div>

      <div class="text-gray-600 text-center font-bold">
        Speak with a licensed insurance agent
      </div>

      <Button
        :to="destination"
        wide
        class="bg-gradient-to-b from-[#00b2ff] to-[#006aff]"
        @click="
          analytics.track('learnMoreClicked', {
            source: $route.fullPath,
            destination,
          })
        "
      >
        <span> Learn More </span>
        <Icon i-heroicons-solid:arrow-right class="w-8 h-8 ml-4" />
      </Button>
    </div>

    <!-- <Modal :open="showBackButtonModal" @close="showBackButtonModal = false">
      <ModalBackButton @close="showBackButtonModal = false" />
    </Modal> -->
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "benefits": ["otc"],
    "smid": {
      "bh": "MULTI-PLAN_HLWEB0624182_M",
      "m10": "MULTI-PLAN_HLWEB0824222_M"
    }
  }
}
</route>
