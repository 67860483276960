<script setup lang="ts">
import cnbc from '../../assets/logos/CNBC_logo.svg'
import newYorkTimes from '../../assets/logos/The_New_York_Times_Logo.svg'
import yahooNews from '../../assets/logos/Yahoo_news_logo.svg'
import foxNews from '../../assets/logos/Fox_News_Channel_logo.svg'

useHead({
  title: `Additional benefits grocery 2024 - ${brand.name}`,
})

const isProd = import.meta.env.MODE === 'production'
const isFromFacebook = query.utm_source?.toLowerCase() === 'facebook'
const isFromFacebookOrGoogle =
  isFromFacebook || query.utm_source?.toLowerCase() === 'google'

provide('isFromFacebookOrGoogle', isFromFacebookOrGoogle)

const isMounted = useMounted()
const isPaidUser = ref(true)

const isMobile = ref(true)
const lowBid = ref(false)

provide('lowBid', lowBid)
const minOtc = computed(() => {
  return states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc
})

const minOtcLtd = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  )
})

const top5Otc = computed(() => {
  return (
    states.find((state) => state.code === ip.lookup?.stateCode)?.top5Otc ?? 500
  )
})

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  isMobile.value = window.innerWidth < 640

  if (isMobile.value && isPaidUser.value && isFromFacebookOrGoogle) {
    enterExperiment('groceryAllowance2024FacebookGoogle')
  }
})

const showDefaultExperience = computed(() => {
  return !isMobile.value
})

const carriers = [
  {
    id: 'newYorkTimes',
    image: newYorkTimes,
    alt: 'New York Times Logo',
  },
  {
    id: 'cnbc',
    image: cnbc,
    alt: 'CNBC Logo',
  },
  {
    id: 'yahooNews',
    image: yahooNews,
    alt: 'Yahoo News Logo',
  },
  {
    id: 'foxNes',
    image: foxNews,
    alt: 'Fox News Logo',
  },
]
const shouldSkipToClicks = ref(false)
const onStepSubmit = (step: Record<string, any>) => {
  if (step.values.haveMedicare !== null) {
    shouldSkipToClicks.value =
      step.values.haveMedicare === false || redirectToClicksFromFacebook.value
  }
}
</script>

<template>
  <Layout
    :header="
      exp?.groceryAllowance2024FacebookGoogle?.newVersion && isMobile
        ? 'countdown'
        : 'simple3'
    "
    :hideHeader="
      !exp?.groceryAllowance2024FacebookGoogle?.newVersion && isMobile
    "
    footer="grocery2024"
    class="container"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-4 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="showDefaultExperience">
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </template>
        <template v-else-if="!isProd">
          Have Medicare? Turning 65? Some Medicare members in
          {{ ip.lookup?.stateName || 'America' }} may qualify for
          <span class="text-red">
            {{ minOtc ? `$${minOtc}/yr or more` : 'an allowance' }}
            towards OTC products
          </span>
          w some Medicare Advantage Plans.
        </template>
        <template v-else-if="query.utm_medium?.toLowerCase() === 'aff'">
          Some Medicare members in
          {{ ip.lookup?.stateName || 'America' }} may qualify for
          <span class="text-red">
            ${{ minOtcLtd }}/yr or more allowance to use towards groceries
          </span>
          with some Medicare Advantage Plans.
        </template>
        <template v-else-if="isFromFacebookOrGoogle && isPaidUser && isMobile">
          Some Medicare members in
          {{ ip.lookup?.stateName || 'America' }} may qualify for
          <span class="text-red">
            ${{ minOtcLtd }}/yr or more allowance to use towards groceries
          </span>
          with some Medicare Advantage Plans.
        </template>
        <template v-else>
          Some Medicare members in
          {{ ip.lookup?.stateName || 'America' }} may qualify for
          <span class="text-red">
            ${{ minOtcLtd }}/yr or more allowance to use towards groceries
          </span>
          with some Medicare Advantage Plans.
        </template>
      </div>

      <template v-if="isMounted">
        <img
          v-if="showDefaultExperience"
          src="../../assets/helpline-seniors-hands-up.png"
          width="1254"
          height="836"
          class="w-[90%] sm:w-[400px] mx-auto"
          alt="Happy seniors in a room with their hands up"
        />
        <img
          v-else-if="exp?.groceryAllowance2024FacebookGoogle?.newVersion"
          src="../../assets/grocery-2024.png"
          width="1254"
          height="836"
          class="w-[80%] sm:w-[400px] mx-auto"
          alt="Smiling elderly woman holding a black allowance card"
        />
        <img
          v-else-if="
            query.utm_source?.toLowerCase() === 'google' ||
            query.utm_source?.toLowerCase() === 'facebook'
          "
          src="../../assets/fruits-black-card.png"
          width="1100"
          height="576"
          class="w-[90%] sm:w-[400px] mx-auto"
          alt="Fruits section in a supermarket and a hand holding a black allowance card"
        />
        <img
          v-else-if="query.utm_medium?.toLowerCase() === 'aff'"
          src="../../assets/envelope-blue-card.png"
          width="1024"
          height="659"
          class="w-[90%] sm:w-[400px] mx-auto"
          alt="An envelope with a blue allowance card"
        />
        <img
          v-else
          src="../../assets/allowance-card-4.png"
          width="504"
          height="331"
          class="w-[90%] sm:w-[400px] mx-auto"
          alt="A hand holding a supermarket receipt with a blue allowance card and groceries over the table"
        />
      </template>

      <UseForm v-slot="{ values }">
        <Form
          id="grocery2024"
          class="!max-w-3xl"
          scrollBehavior="auto"
          :steps="[
            { id: 'medicareab' },
            { id: 'medicaid', if: !shouldSkipToClicks },
            { id: 'us', if: !isProd && !shouldSkipToClicks },
            { id: 'wantGrocery', if: isPaidUser && !shouldSkipToClicks },
            { id: 'loading', if: !shouldSkipToClicks },
            {
              id: 'call',
              if: !shouldSkipToClicks && (isProd ? isMobile : true),
            },
            { id: 'clicks' },
            //if: isProd ? !isMobile : false
            { id: 'call-now', if: false },
          ]"
          product="medicare"
          @step-submit="onStepSubmit"
        />
      </UseForm>

      <div
        class="flex flex-wrap justify-center gap-4 mt-4"
        v-if="
          exp?.groceryAllowance2024FacebookGoogle?.newVersion &&
          isPaidUser &&
          isMobile
        "
      >
        <img
          v-for="carrier in carriers"
          :key="carrier.id"
          :src="carrier.image"
          class="w-16 object-contain"
          :alt="carrier.alt"
        />
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer"],
    "smid": {
      "m10": "MULTI-PLAN_EHWEB082324_M",
      "bh": "MULTI-PLAN_EHWEB062302_M",
      "boomer": "MULTI-PLAN_EHWEB082331_M",
      "med10": "MULTI-PLAN_EHWEB092334_M"
    },
    "xsmid": {
      "m10": "MULTI-PLAN_EHWEB082324_M",
      "bh": "MULTI-PLAN_EHWEB062302_M",
      "boomer": "MULTI-PLAN_EHWEB082331_M",
      "med10": "MULTI-PLAN_EHWEB092334_M"
    },
    "robots": "noindex"
  }
}
</route>
