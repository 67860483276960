<script setup lang="ts">
import emily from '~/assets/emily-b008.png'
import AudioPlayer from '~/components/AudioPlayer.vue'
import {
  AnswerButton,
  Message,
  RedirectButtonMessage,
  SelectedOptionEmit,
} from '~/modules/chat-ad'

const router = useRouter()
const route = useRoute()

const emit = defineEmits<{
  (
    e: 'selectOption',
    { nextStepId, eventProperty, eventValue }: SelectedOptionEmit
  ): void
  (e: 'onPostCall', ms: number): void
}>()

const props = defineProps<{
  message: Message
  renderMessageWaiting: boolean
  connectTop?: boolean
  connectBottom?: boolean
  enablePostCall?: boolean
}>()

onMounted(() => {
  if (
    props.message.type === 'redirect' &&
    props.message.answers &&
    props.message.answers?.length >= 1
  ) {
    selectOption(props.message.answers[0])
  }
})

const answers = computed((): AnswerButton[] => {
  return props.message.answers ?? []
})

const selectOption = (answer: AnswerButton) => {
  const selectedOption: SelectedOptionEmit = {
    nextStepId: answer.answer,
  }

  if (answer.event) {
    selectedOption.eventProperty = answer.event.property
    selectedOption.eventValue = answer.event.value
  }
  emit('selectOption', selectedOption)
  if (answer.type === 'redirect') {
    redirectTo(answer.ctaUrl)
  }
}

const submitZip = (zip: string, message: Message) => {
  if (message.answers) {
    const selectedOption: SelectedOptionEmit = {
      nextStepId: message.answers[0].answer,
    }
    selectedOption.eventProperty = message.answers[0].event?.property
    selectedOption.eventValue = zip
    emit('selectOption', selectedOption)
  }
}

const redirectTo = (url?: string) => {
  const destination = url ?? '/go-4-benefits'
  let learnMoreData = {
    source: route.fullPath,
    destination,
    button: 'no',
  }
  if (props.message.trackPayload) {
    learnMoreData = {
      ...learnMoreData,
      ...props.message.trackPayload,
    }
  }
  analytics.track('learnMoreClicked', learnMoreData)
  router.push(destination)
}

const stopAudioOnClick = (
  callbackFn: (...args: any[]) => Promise<void> | any
) => {
  return (...args: any[]) => {
    const stopAudioEvent = new CustomEvent('stop-audio', {})
    window.dispatchEvent(stopAudioEvent)
    callbackFn(...args)
  }
}

const postCall = (ms: number) => {
  if (props.enablePostCall) {
    emit('onPostCall', ms)
  }
}
</script>

<template>
  <div v-if="message.type === 'zip'" :class="['chat my-2']">
    <div class="w-8"> &nbsp;</div>
    <div class="flex flex-col gap-2">
      <label
        class="font-semibold"
        v-if="message.content"
        v-html="message.content"
      >
      </label>
      <ZipCode
        @submit-zip-code="(zip: string) => submitZip(zip, message)"
        button-label="Send"
        :disabled="message.clicked"
      />
    </div>

    <div class="w-8"> &nbsp;</div>
  </div>
  <div
    v-else
    :class="['chat', message.from, message.type === 'redirect' && 'hidden']"
  >
    <div class="emily-wrapper flex relative items-end">
      <div
        class="emily-avatar flex rounded-full relative dark:text-white"
        v-if="message.shouldShowAvatar && message.from === 'emily'"
      >
        <img
          :src="emily"
          class="w-8 h-8 rounded-full self-end"
          alt="Emily Avatar"
        />
        <div
          class="online rounded-full h-3 w-3 bg-emerald-500 bottom-0 right-0 absolute border border-gray-300"
        >
        </div>
      </div>
    </div>
    <div
      v-if="renderMessageWaiting && message.from === 'emily'"
      :class="[
        'p-4 rounded-full flex items-start max-w-[80px]',
        ' dark:bg-[#3A3A3A] dark:text-white bg-[#f1f1f1]',
        connectTop ? 'connected-top' : '',
        connectBottom ? 'connected-bottom' : '',
        message.from,
      ]"
    >
      <div class="typing-indicator">
        <span></span><span></span><span></span>
      </div>
    </div>

    <div
      v-else
      :class="[
        'message',
        connectTop ? 'connected-top' : '',
        connectBottom ? 'connected-bottom' : '',
        message.from,
        message.type,
      ]"
    >
      <span v-if="message.type === 'text'" v-html="message.content"></span>
      <div v-if="message.type === 'link'">
        <a v-if="message.link" :href="message.link">
          <div
            :class="[
              'bg-gray-200 dark:text-black flex  rounded-tr-2xl',
              connectTop ? 'rounded-tl' : 'rounded-tl-2xl',
            ]"
          >
            <img
              v-if="message.image"
              :class="[
                'w-16 h-16 object-contain',
                connectTop ? 'rounded-tl' : 'rounded-tl-2xl',
              ]"
              :src="message.image"
              alt="Link image"
            />
            <div class="p-2" v-html="message.content"></div>
          </div>
          <span class="text-blue-500 p-2 underline">{{ message.link }}</span>
        </a>
      </div>
      <div
        :class="['text-gray-800 flex flex-col gap-2', 'dark:text-white']"
        v-else-if="message.type === 'buttons'"
      >
        <span v-if="message.content" v-html="message.content"></span>
        <template v-for="answer in answers">
          <button
            class="button"
            :disabled="message.clicked"
            v-html="answer.text"
            v-if="answer.type === 'redirect'"
            @click="() => stopAudioOnClick(selectOption)(answer)"
          >
          </button>
          <button
            class="button"
            :disabled="message.clicked"
            v-html="answer.text"
            v-else
            @click="() => stopAudioOnClick(selectOption)(answer)"
          >
          </button>
        </template>
      </div>
      <div
        :class="['text-gray-800 flex flex-col gap-2']"
        v-else-if="message.type === 'callButton'"
      >
        <ClickToCall
          v-slot="{ handleClick }"
          :props="{
            ctcLocation: 'chat-last-step',
          }"
          @return="postCall"
          :no-post-call-review="true"
        >
          <a
            :href="phone.uri"
            @click="(event) => stopAudioOnClick(handleClick)(event)"
            class="flex justify-center p-2 bg-red rounded-3xl flex gap-2 !text-white px-4 items-center shadow-lg flex-grow w-full"
          >
            <Icon
              i-heroicons-solid:phone
              class="w-5 h-5 animate-shake text-white"
            />
            <span v-html="message.callButtonLabel ?? 'Call Now'"></span>
          </a>
        </ClickToCall>
        <div class="flex justify-center space-x-3">
          <div class="relative flex w-2 h-2">
            <div
              class="animate-ping absolute top-2 inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
            >
            </div>
            <div
              class="relative inline-flex top-2 rounded-full h-2 w-2 bg-green-500"
            >
            </div>
          </div>
          <div class="sm:text-lg font-medium dark:text-white">
            Licensed Insurance Agents
          </div>
        </div>
      </div>
      <div
        :class="['mr-2 message text-gray-800']"
        v-else-if="message.type === 'redirectButton'"
      >
        <button
          class="button w-40"
          v-html="message.content"
          @click="redirectTo((message as RedirectButtonMessage).ctaUrl)"
        >
        </button>
      </div>
      <div v-else-if="message.type === 'audio' && message.audio">
        <AudioPlayer
          :audio-path="message.audio.src"
          :auto-play="message.audio.autoplay"
          :is-muted="false"
          :show-progress="true"
          :show-play="true"
          :show-mute="false"
        />
      </div>
      <div v-else-if="message.type === 'image'">
        <img
          :src="message.image"
          :alt="message.content"
          class="w-full min-h-20 object-contain"
        />
      </div>
      <div v-if="message.type === 'carriers'">
        <span v-if="message.content" v-html="message.content"></span>
        <ClickToCall
          v-slot="{ handleClick }"
          v-for="carrier in message.carriers"
        >
          <Button siz="sm" :to="carrier.phone" @click="handleClick">
            Call
            <span> {{ carrier.carrier }} </span>
          </Button>
        </ClickToCall>
      </div>
    </div>

    <div>&nbsp;</div>
  </div>
</template>

<style scoped>
.chat {
  @apply grid grid-cols-3 gap-2;
  grid-template-columns: 2rem auto 2rem;

  &.user {
    grid-template-columns: 2rem auto 0px;
  }
}

.message {
  @apply flex flex-col gap-4 w-auto justify-center rounded-3xl px-4 py-2;

  &.link {
    @apply p-1;
  }

  &.image {
    @apply p-0 overflow-hidden;
  }
}

.button {
  @apply bg-[#fcfcfc] text-black font-bold border-transparent shadow-sm py-2 min-w-[200px] w-full rounded-3xl;
  @apply dark:text-black dark:bg-gray-200;
  @apply disabled:bg-gray-200 disabled:text-gray-300 text-wrap py-1 px-4;
}

.message.emily {
  @apply dark:from-[#1c1c1c] dark:to-black dark:text-white bg-gradient-to-r to-[#efefef] from-[#ededed] text-gray-800 justify-self-start;
}

.message.user {
  @apply text-white justify-self-end w-auto my-2;
  background: #00c6ff;
  background: linear-gradient(to right, #00c6ff, #0072ff);
}

.connected-top.emily {
  @apply rounded-2xl rounded-tl;
}

.connected-bottom.emily {
  @apply rounded-2xl rounded-bl;
}

.connected-bottom.connected-top.emily {
  @apply rounded-2xl rounded-l;
}

.connected-top.user {
  @apply rounded-2xl rounded-tr;
}

.connected-bottom.user {
  @apply rounded-2xl rounded-br;
}

.connected-bottom.connected-top.user {
  @apply rounded-2xl rounded-r;
}

.typing-indicator {
  display: flex;
  justify-content: start;
  max-width: 120px;
  padding: 4px;
}

.typing-indicator span {
  background-color: #9ca3af;
  border-radius: 9999px;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  animation: typing 1.4s infinite;
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

.list-enter-active,
.list-move {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-move {
  transform: translateY(20px);
  opacity: 0;
}

.list-enter-to {
  transform: translateY(0);
  opacity: 1;
}
</style>
